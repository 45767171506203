.block-text {
    @media (min-width: $smartphone) {
        --padding-content-v-top: var(--padding-s);
        --padding-content-v-bottom: var(--padding-s);
    }

    @media (min-width: $smartphone) {
        --padding-content-v-top: var(--padding-xxl);
        --padding-content-v-bottom: var(--padding-s);
        --padding-content-h-right: var(--padding-xxxxl);
    }
}

.block-text {
    > .content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
    }

    .text-top {
        @include font-xl();
    }

    .text-bottom {
        @include font-m();
    }
    
    @media (max-width: $smartphone) {
        .text-top {
            @include font-l();
        }
    
        // .text-bottom {
        //     @include font-s();
        // }
    }
}
