.block-corporate-kitdigital {
    --padding-content-v-top: var(--padding-m);
    --padding-content-v-bottom: var(--padding-m);
}

.block-corporate-kitdigital {
    > .content {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        flex-direction: column;
    }

    .icon {
        width: 100%;
    }
}
