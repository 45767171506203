.block-contact {
    // background-color: var(--white);
    // color: var(--black);

    > .content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    a {
        @include font-xl();
    }
        
    @media (max-width: $smartphone) {
        a {
            @include font-l();
        }
    }
}
