.block-slider-default {
    --width: 100%;
    --gap: var(--padding-xs);
    --margin: var(--padding-xs);

    &[data-fit="horizontal"] {
        --height: auto;
        --max-height: auto;
        --width-slide: 30vw;
    }

    &[data-fit="vertical"] {
        --height: 60vw;
        --max-height: 80vh;
        --width-slide: auto;
    }

    width: var(--width);
    margin: var(--margin) 0;
    padding: 0;
    user-select: none;
}

.block-slider-default__slider {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    
    cursor: grab;    
    &:active {
        cursor: grabbing;
    }

    > .holder {
        order: 1;
        position: relative;
        height: var(--height);
        max-height: var(--max-height);
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        gap: var(--gap);
    }
}

.block-slider-default__item {
    position: relative;
    flex: 0 0 var(--width-slide);
    width: var(--width-slide);
    height: 100%;
    overflow: hidden;

    svg {
        height: 100%;
        width: auto;
        display: block;
    }

    img {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }
}

.block-slider-default__controls {
    order: 2;
    position: relative;
    width: 100%;
    height: auto;
    padding: var(--padding-xs);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    
    button {
        pointer-events: all;
        @include basic-a();
        @include font-sans(1);
        background-color: transparent;
    }
}