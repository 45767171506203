.tag {
    --color: var(--black);
    --bg: var(--white);
    
    &.--alt {
        --color: var(--white);
        --bg: var(--black);
    }
}

.tag {
    color: var(--color);
    background-color: var(--bg);
    margin-top: rem(1);
    display: inline-block;
    padding: 0 rem(2);
    text-transform: uppercase;

    @include font-l(1.05);
    
    &.--sm {
        @include font-m();
    }

    strong {
        font-weight: normal;

        @include font-serif-l(1.05);
    }
}
