.card {
    --ratio: .8;
    
    --padding-content-v-top: var(--padding-xs);
    --padding-content-v-bottom: var(--padding-xs);
    --padding-content-h-left: var(--padding-m);
    --padding-content-h-right: var(--padding-m);
    --padding-content-h-right: var(--padding-m);
    --arrow-height: #{rem(13)};
    --arrow-width: #{rem(35)};
    
    @media (max-width: $smartphone) {
        --arrow-height: #{rem(10)};
        --arrow-width: #{rem(27)};
    }

    &.--is-full-width { --object-fit: cover; }

    &:not(.--is-full-width) {
        --object-fit: contain;
        --padding-media: var(--padding-xxl) var(--padding-l);
    }

    &.--primary,
    & {
        --color: var(--black);
        --bg: var(--white);
    }
    
    &.--secondary {
        --color: var(--white);
        --bg: var(--black);
    }
}

.card {
    color: var(--color);
    background-color: var(--bg);
    position: relative;
    display: block;

    a,
    button {
        @include basic-a();
        color: var(--color);
        padding: 0;
        background-color: transparent;
        pointer-events: all;
    }

    svg .cls-1 {
        fill: var(--color) !important;
        stroke: var(--color) !important;
    }

    &::before {
        pointer-events: none;
        display: block;
        content: '';
        position: relative;
        width: 100%;
        padding-top: calc(100% / var(--ratio));
    }

    &.--hidden {
        opacity: 0 !important;
        pointer-events: none !important;

        > * {
            pointer-events: none;
            cursor: auto !important;
        }
    }
}

.card .slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    cursor: grab;    
    &:active {
        cursor: grabbing;
    }

    > .holder {
        position: relative;
        height: 100%;
        max-height: 100%;
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
    }

    &__item {
        position: relative;
        flex: 0 0 100%;
        width: 100%;
        height: 100%;
        overflow: hidden;

        * {
            pointer-events: none;
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
        }
    }

    &__controls {
        position: absolute; 
        padding-bottom: var(--padding-content-v-bottom);
        padding-right: var(--padding-content-h-right);
        padding-left: var(--padding-content-h-right);
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: flex-end;
        gap: var(--padding-m);
        align-items: center;
        pointer-events: none;
        width: 100%;
        
        button {
            pointer-events: all;
            background-color: transparent;
            padding: 0;
            display: block;
            
            svg {
                pointer-events: none;
                height: var(--arrow-height);
                width: var(--arrow-width);
                display: block;
            }
        }
        
        @media (max-width: $smartphone) {
            --padding-content-h-left: var(--padding-s);
            --padding-content-h-right: var(--padding-s);
            --padding-content-v-bottom: 0;
            gap: var(--padding-s);

            button {
                padding: var(--padding-xs) 0;
            }
        }
    }

    &__number {
        display: flex;
        position: absolute;
        top: var(--padding-m);
        right: var(--padding-m);
        color: var(--color);
        
        @include font-serif-s();
    }
}

.card .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: var(--padding-content-v-top) var(--padding-content-h-right) var(--padding-content-v-bottom) var(--padding-content-h-left);
}

.card .media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    figure {
        padding: var(--padding-media);
        width: 100%;
        height: 100%;

        img,
        video {
            position: relative;
            width: 100%;
            height: 100%;
            object-position: center center;
            object-fit: var(--object-fit);
        }
    }
}
