.filters {
    --padding-v: var(--padding-xs);
    --padding-h: var(--padding-m);
    --height: #{rem(30)};
    --width: #{rem(100)};
    --gap: #{rem(10)};
    // --border: var(--primary-color);
    // --bg: var(--primary-bg);
    // --color: var(--primary-color);
    --border: var(--primary-bg);
    --bg: var(--primary-color);
    --color: var(--primary-bg);
    
    @media (min-width: $smartphone) {
        --gap: #{rem(20)};
        --width: #{rem(140)};
    }

    button.--active {
        // --bg: var(--primary-color);
        // --color: var(--primary-bg);
        --bg: var(--primary-bg);
        --color: var(--primary-color);
    }

    @include isCursor() {
        button:hover,
        a:hover {
            // --bg: var(--primary-color);
            // --color: var(--primary-bg);
            --bg: var(--primary-bg);
            --color: var(--primary-color);
        }
    }
}

.filters {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--gap);

    position: fixed;
    @include z-index($z-index-header);

    button,
    a {
        @include basic-a();

        padding: 0;
        background-color: transparent;
        @include font-l();
        text-transform: uppercase;
        
        height: var(--height);
        width: var(--width);
        
        color: var(--color);
        border: 1px solid var(--border);
        background-color: var(--bg);
    }
    
    @media (max-width: $smartphone) {
        button,
        a {
            @include font-m();
        }

        top: rem(11);
        left: 50%;
        transform: translate3d(-50%, 0, 0);
    }

    @media (min-width: $smartphone) {
        top: rem(18);
        left: rem(200);
    }
}
