.block-corporate-logo {
    --width: #{rem(88)};
    --height: #{rem(26)};
}

.block-corporate-logo {
    .logo {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);

        width: var(--width);
        height: var(--height);

        svg {
            height: 100%;
            width: 100%;
            fill: var(--color);
        }
    }

    > .content {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        text-align: center;
    }
}
