@use "sass:math";

#CMP {
    --border: var(--black);
    --color: var(--black);
    --bg: var(--white);
    --border-size: #{rem(1)};

    --padding-content-v-top: var(--padding-xs);
    --padding-content-v-bottom: var(--padding-xs);
    --padding-content-h-left: var(--padding-m);
    --padding-content-h-right: var(--padding-m);
    
    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
        
        --padding-content-v-top: var(--padding-m);
        --padding-content-v-bottom: var(--padding-m);
    }

    @media (max-width: $smartphone) {
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }
}

#CMP {
    @include z-index($z-index-windows);
    @include font-l();

    position: fixed;
    right: var(--padding-content-h-right);
    bottom: var(--padding-content-v-bottom);
    max-height: 80vh;
    opacity: 0;
    
    border: var(--border-size) solid var(--border);
    background-color: var(--bg);
    color: var(--color);
    
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    
    padding: var(--padding-content-v-top) var(--padding-content-h-right) var(--padding-content-v-bottom) var(--padding-content-h-left);

    > .btns {
        display: flex;
        flex-wrap: wrap;
        gap: var(--padding-s);
    }

    .btn {
        @include basic-a();
        @include font-m();
    
        background-color: transparent;
        color: var(--color);
        padding: 0;
    }

    a {
        @include basic-a();
        color: var(--color);
        text-decoration: underline;
    }

    @media (max-width: $smartphone) {
        left: var(--padding-content-h-left);
        bottom: var(--padding-xxl);

        @include font-xl();

        .btn {
            @include font-l();
        }
    }

    @media (min-width: $smartphone) {
        width: 30vw;
    }
}

#CMP > .information {
    @include font-m();

    flex: 1 0 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0 var(--padding);
    margin-top: var(--padding-s);

    &[aria-expanded='false'] {
        display: none;
    }
}

#CMP > .information > .information__type {
   width: 100%;
   display: flex;
   justify-content: space-between;
   padding: var(--padding-xs) 0;
   
   &:not(:last-of-type) {
    border-top: var(--border-size) solid var(--color);
   }
      
   > .title {
        &:empty {
            display: none;
            visibility: hidden;
            pointer-events: none;
        }

        font-size: 1em;
        font-weight: bold;
        white-space: normal;
   }

   > .text {
        flex: 1;
        width: 100;
        white-space: normal;
        padding-right: var(--padding-xs);
   }

   > .checks {
        flex: 0 0 auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: var(--grey);
        width: #{rem(50)};
        text-align: right;
   }

   > .checks  > .check {
        --width: #{rem(38)};
        --height: #{rem(22)};
        --radius: #{rem(11)};
        --thumb-width: #{rem(16)};
        --thumb-offset: calc((var(--height) - var(--thumb-width))/2);
        --position: var(--thumb-offset);
        --opacity: 1;

        &.--disabled,
        &.--active {
            --position: calc(var(--width) - var(--thumb-width) - var(--thumb-offset));
        }

        &.--disabled {
            --opacity: .4;
            pointer-events: none;
        }

        position: relative;
        width: var(--width);
        height: var(--height);
        border-radius: var(--radius);
        
        &::before {
            content: '';
            position: absolute;
            top:0;
            left: 0;
            right: 0;
            bottom: 0;
            border: var(--border-size) solid var(--color);
            border-radius: var(--radius); 
            background-color: var(--bg);
        }

        &::after {
            content: '';
            position: absolute;
            top: var(--thumb-offset);
            left: 0;
            width: var(--thumb-width);
            height: var(--thumb-width);
            border-radius: var(--height); 
            background-color: var(--color);
            transform: translateX(var(--position));
            transition: transform .1s ease-out;
        }
   }
}

#CMP > .information > .information__controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 0 var(--padding);
    margin-top: var(--padding-s);
}
