.gallery {
    margin-top: var(--padding-l);

    > *:not(:last-child) {
        margin-bottom: var(--padding-xs);
    }

    @media (min-width: $smartphone) {
        margin-top: rem(150);
        padding-left: rem(280);
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: var(--padding-m);
        
        > *:not(:last-child) {
            margin-bottom: var(--padding-m);
        }
        
        .image {
            grid-column: span var(--cols);

            * {
                pointer-events: none;
                -webkit-user-drag: none;
                -khtml-user-drag: none;
                -moz-user-drag: none;
                -o-user-drag: none;
                user-drag: none;
            }
        }
    }
}
