.block-corporate-legal {
    --padding-content-v-top: var(--padding-m);
    --padding-content-v-bottom: var(--padding-m);
}

.block-corporate-legal {
    > .content {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        flex-direction: column;
        gap: var(--padding-m);
    }

    @include font-xl();

    @media (max-width: $smartphone) {
        @include font-l();
    }
}
