.block-legal {
    button {
        background: var(--primary-bg);
        height: rem(75);
        width: rem(75);
        
        svg {
            height: rem(9);
            width: rem(23);
            pointer-events: none;
        }
    }
    
    .side {
        background-color: var(--primary-color);
        padding: var(--padding-s);
        
        display: flex;
        gap: var(--padding-s);

        > div {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

    .summary {
        > * {
            margin-bottom: var(--padding-m);
        }
    }

    @media (max-width: $smartphone) {
        padding-bottom: var(--padding-l);

        .summary {
            padding: 0 var(--padding-s);
            @include font-m();
        }
        
        .title {
            @include font-xxl();
            padding: var(--padding-m) var(--padding-s);
        }
    }

    @media (min-width: $smartphone) {
        padding-right: var( --padding-m);
        display: flex;
        gap: var(--padding-m);

        .side {
            align-items: flex-end;
            flex: 0 0 rem(280);
            height: rem(360);

            > div {
                justify-content: flex-end;
            }
        }
        
        .summary {
            @include font-l();
            flex: 0 0 rem(810);
            padding-top: var(--padding-m);
        }
        
        .title {
            @include font-xxl();
            flex-grow: 1;
            padding-top: var(--padding-m);
        }
    }
}
