.billboard-contact {
    button {
        background: var(--primary-bg);
        display: flex;
        justify-content: center;
        align-items: center;

        height: rem(75);
        width: rem(75);
        
        svg {
            height: rem(13);
            width: rem(33);
            pointer-events: none;
            display: block;
        }
    }

    .info {
        background-color: var(--primary-color);
        padding: var(--padding-s);
        
        display: flex;
        gap: var(--padding-s);

        > div {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

    .summary {
        > * {
            margin-bottom: var(--padding-m);
        }

        > .content {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            flex-direction: column;
        }

        .text {
            @include font-xl();
        }

        a {
            @include font-xl();
            display: block;
            color: var(--black);
            text-decoration: none;
        }
        
        .text {
            margin-bottom: var(--padding-l);
        }
        
        .rrss {
            margin-bottom: var(--padding-l);
        }
        
        @media (max-width: $smartphone) {
            .text, a {
                @include font-l();
            }
        }
    }

    @media (max-width: $smartphone) {
        .summary {
            padding: 0 var(--padding-s) var(--padding-s);
        }
        
        .title {
            @include font-xl();
            padding: var(--padding-m) var(--padding-s);
        }
    }

    @media (min-width: $smartphone) {
        padding-left: calc(rem(280) + var(--padding-m));
        padding-right: var( --padding-m);
        display: flex;
        gap: var(--padding-m);

        .info {
            align-items: flex-end;
            flex: 0 0 rem(280);
            width: rem(280);
            height: rem(360);
            position: absolute;
            left: 0;
            top: 0;

            > div {
                justify-content: flex-end;
            }
        }
        
        .summary {
            flex: 0 0 rem(810);
            padding-top: var(--padding-m);
        }
        
        .title {
            @include font-xxl();
            flex-grow: 1;
            padding-top: var(--padding-m);
        }
    }
}

.info-panel {
    @include z-index(1);

    background-color: var(--primary-color);
    padding: var(--padding-s);
    display: flex;
    gap: var(--padding-s);
    align-items: flex-end;
    width: rem(280);
    height: rem(360);
    position: absolute;
    left: 0;
    top: 0;
    
    button {
        background: var(--primary-bg);
        display: flex;
        justify-content: center;
        align-items: center;
        
        height: rem(75);
        width: rem(75);
        
        svg {
            height: rem(13);
            width: rem(33);
            pointer-events: none;
            display: block;
        }
    }
    
    > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
    }
}