.block-media {
    &:not(.--gallery) {
        --padding-content-v-bottom: var(--padding-l);
    }

    .text {
        @media (min-width: $smartphone) {
            --padding-content-v-top: var(--padding-xxl);
            --padding-content-v-bottom: var(--padding-s);
            --padding-content-h-right: var(--padding-xxxxl);
        }
    }
}

.block-media {
    .content {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        flex-direction: column;
        pointer-events: none;

        .text {
            @include font-l();
        }
    }

    .slider__item .text {
        @include font-m();
        padding: var(--padding-content-v-top) var(--padding-content-h-right) var(--padding-content-v-bottom) var(--padding-content-h-left);
    }

    .link {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        pointer-events: initial;

        svg {
            height: rem(90);
            width: rem(90);

            path {
                fill: var(--color);
            }
        }
    }

    @media (max-width: $smartphone) {
        .slider__item .text {
            @include font-s();
        }
    }
}
