.not-found {
    --padding-h: var(--padding-xs);
}

.not-found {
    // @include font-l();

    padding: 35vh var(--padding-h) 5vh;

    display: flex;
    align-items: center;
    justify-content: center;

    > div {
        text-align: center;
    }

    .filters {
        margin-top: var(--padding-xxxl);
        text-align: center;
        position: static;

        a {
            --width: auto;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 0 var(--padding-s);
        }
    }

    .icon {
        width: 100%;
        margin: auto;

        svg {
            width: 100%;
            height: auto;
        }
    }

    @media (max-width: $smartphone) {
        padding: 35vh var(--padding-h) 5vh;

        .icon {
            width: 100%;
        }

        .filters {
            margin-top: var(--padding-xxl);
        }
    }
}
