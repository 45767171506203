.billboard-project {
    button {
        background: var(--primary-bg);
        display: flex;
        justify-content: center;
        align-items: center;

        height: rem(75);
        width: rem(75);
        
        svg {
            height: rem(13);
            width: rem(33);
            pointer-events: none;
            display: block;
        }
    }

    .info {
        background-color: var(--primary-color);
        padding: var(--padding-s);
        
        display: flex;
        gap: var(--padding-s);

        > div {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

    .summary {
        > * {
            margin-bottom: var(--padding-m);
        }
    }

    @media (max-width: $smartphone) {
        .summary {
            padding: 0 var(--padding-s) var(--padding-s);
            @include font-m();
        }
        
        .title {
            @include font-xl();
            padding: var(--padding-m) var(--padding-s);
        }
    }

    @media (min-width: $smartphone) {
        gap: var(--padding-m);
        display: grid;
        grid-template-columns: repeat(5, 1fr);

        .info {
            align-items: flex-end;
            grid-column: span 1;
            height: rem(360);
            position: absolute;
            left: 0;
            top: 0;

            > div {
                justify-content: flex-end;
            }
        }
        
        .summary {
            @include font-l();
            grid-column: span 3;
            padding-top: var(--padding-m);
        }
        
        .title {
            @include font-xxl();
            grid-column: 2 / 3;
            padding-top: var(--padding-m);
        }
    }
}

.info-panel {
    @include z-index(1);

    background-color: var(--primary-color);
    padding: var(--padding-s);
    display: flex;
    gap: var(--padding-s);
    align-items: flex-end;
    width: rem(280);
    height: rem(360);
    position: absolute;
    left: 0;
    top: 0;
    
    button {
        background: var(--primary-bg);
        display: flex;
        justify-content: center;
        align-items: center;
        
        height: rem(75);
        width: rem(75);
        
        svg {
            height: rem(13);
            width: rem(33);
            pointer-events: none;
            display: block;
        }
    }
    
    > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
    }
}