@use "sass:math";

#Footer {    
    @media (min-width: $smartphone) {}
    @media (max-width: $smartphone) {}
}

#Footer {
    display: none;
}
