.block-corporate-contact {
    --padding-content-v-bottom: var(--padding-l);
}

.block-corporate-contact {
    > .content {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        flex-direction: column;
    }

    a {
        @include font-xl();
        display: block;
    }

    .rrss {
        margin-bottom: var(--padding-l);
    }
    
    @media (max-width: $smartphone) {
        a {
            @include font-l();
        }
    }
}
