@use "sass:math";

:root {
  --y-header: 0;

  --padding-xxxxl: #{math.div(62px, 16px) * 1rem};
  --padding-xxxl: #{math.div(48px, 16px) * 1rem};
  --padding-xxl: #{math.div(36px, 16px) * 1rem};
  --padding-xl: #{math.div(30px, 16px) * 1rem};
  --padding-l: #{math.div(24px, 16px) * 1rem};
  --padding-m: #{math.div(12px, 16px) * 1rem};
  --padding-s: #{math.div(8px, 16px) * 1rem};
  --padding-xs: #{math.div(5px, 16px) * 1rem};
  
  --header-height: #{math.div(77px, 16px) * 1rem};
  --main-cols: 5;
  
  @media (max-width: $smartphone) {
      --main-cols: 2;
  }
}
