@use "sass:math";

:root {
    --font-sans: 'Archivo', sans-serif;
    // --font-serif: 'Source Serif 4', sans-serif;
    --font-serif: 'Times News Roman', serif;
    
    --font-size-xxl: #{math.div(40px, 16px) * 1rem};
    --font-size-xl: #{math.div(21px, 16px) * 1rem};
    --font-size-l: #{math.div(18px, 16px) * 1rem};
    --font-size-m: #{math.div(14px, 16px) * 1rem};
    --font-size-s: #{math.div(10px, 16px) * 1rem};
    
    --font-size-serif-s: #{math.div(12px, 16px) * 1rem};

    --zoom: 1;
    --line-height-sans: auto;
    --line-height-serif: auto;
    --letter-spacing: -0.07em;
    --letter-spacing-serif: -0.1em;

    --min-font-size: 10px;
    --max-font-size: 48px;
    --font-size: clamp(var(--min-font-size), 1.1111vw * var(--zoom), var(--max-font-size));
    font-size: var(--font-size);
    
    @media (max-width: $smartphone) {
        --font-size: 16px;
        --font-size-l: #{math.div(16px, 16px) * 1rem};
    }
}

@mixin font-sans($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: normal;
    letter-spacing: var(--letter-spacing);
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-serif($line-height:0) {
    font-family: var(--font-serif);
    font-weight: normal;
    font-style: italic;
    letter-spacing: var(--letter-spacing-serif);
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-serif);  }
}

@mixin font-xxl($line-height: 0) {
    @include font-sans($line-height);
    font-size: var(--font-size-xxl);
}

@mixin font-xl($line-height: 0) {
    @include font-sans($line-height);
    font-size: var(--font-size-xl);
}

@mixin font-l($line-height: 0) {
    @include font-sans($line-height);
    font-size: var(--font-size-l);
}

@mixin font-m($line-height: 0) {
    @include font-sans($line-height);
    font-size: var(--font-size-m);
}

@mixin font-s($line-height: 0) {
    @include font-sans($line-height);
    font-size: var(--font-size-s);
}

@mixin font-serif-l($line-height: 0) {
    @include font-serif($line-height);
    font-size: var(--font-size-l);
}

@mixin font-serif-s($line-height: 0) {
    @include font-serif($line-height);
    font-size: var(--font-size-serif-s);
}
