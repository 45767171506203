.block-project {
    --padding-content-v-bottom: var(--padding-l);
}

.block-project {
    .content {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        flex-direction: column;
    }
}
