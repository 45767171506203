.default {
    .row,
    .grid {
        display: grid;
        grid-template-columns: repeat(var(--main-cols), 1fr);
        width: 100%;
    }
    
    @media (max-width: $smartphone) {
        .grid {
            padding-top: var(--header-height);
        }
    }

    @media (min-width: $smartphone) {
        height: 100vh;
        width: 100vw;
        position: relative;

        > .fullscreen {
            left: 0;
            overflow: hidden;
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            
            .holder {
                width: 100%;
                height: 100%;
            }
        }
    }
}
